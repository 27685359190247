import React from 'react';

import Application from 'components/pages/home/application';
import Features from 'components/pages/home/features';
import Hero from 'components/pages/home/hero';
import Special from 'components/pages/home/special';
import Support from 'components/pages/home/support';
import MainLayout from 'layouts/main';

const HomePage = () => (
  <MainLayout>
    <Hero />
    <Application />
    <Features />
    <Special />
    <Support />
  </MainLayout>
);

export default HomePage;
